const extend = require(`extend`);
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);

module.exports = function ({describe, it, expect}) {

    const environment_entity_list = [
        {
            environment: `alpha`,
            data_for_web_common_login: {
                userName: `lynBuyer`,
                userPassword: `aaa`,
                verificationCode: `0987`,
            },
        },
        {
            environment: `beta`,
            data_for_web_common_login: {
                userName: `Jyfadmin`,
                userPassword: `aaa`,
                verificationCode: `0987`,
            },
        },
        {
            environment: `release`,
            data_for_web_common_login: {
                userName: `leining`,
                userPassword: `aaa`,
                verificationCode: `0987`,
            },
        },
    ];

    _.each(environment_entity_list, function (environment_entity) {
        const environment = environment_entity.environment;
        const data_for_web_common_login = environment_entity.data_for_web_common_login;
        const userName = data_for_web_common_login.userName;
        const userPassword = data_for_web_common_login.userPassword;
        const verificationCode = data_for_web_common_login.verificationCode;

        describe(`${environment}`, function () {

            describe(`test case 002`, function () {
                it(`1+1=2`, function (done) {
                    const actual_value = 1 + 1;
                    const expect_value = 2;
                    expect(actual_value).to.eql(expect_value);
                    done();
                });

            });

        });

    });


};
