import Q from 'q';
import _ from 'underscore';
import run_mocha from '@/lib/common-service/run_mocha';
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import breadcrumb_api from '@/component/admin-layout/1.0.0/component/breadcrumb/api';

export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        __run_mocha() {
            const __this = this;
            run_mocha();
        },

        __btn_run_mocha_click() {
            const __this = this;
            __this.__run_mocha();
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        const __this = this;
        const $route = __this.$route;
        const grep = $route.query.grep;
        const tag_name = `mocha-${grep}`;

        __this.__run_mocha();

        history_tag_api.modify_tag_name_by_route({
            route: $route,
            tag_name,
        });

        breadcrumb_api.set_entity_list_by_get_entity_list({
            get_entity_list(p) {
                const original_entity_list = p.original_entity_list;
                if (original_entity_list.length <= 0) return original_entity_list;
                const last = _.last(original_entity_list);
                last.name = tag_name;
                return original_entity_list;
            },
        });
    },
    deactivated() {
        // const __this = this;
    },
}
