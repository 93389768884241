const extend = require(`extend`);
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);

const init_test_case_001 = require(`./mocha/test_case_001.mocha.js`);
const init_test_case_002 = require(`./mocha/test_case_002.mocha.js`);

const init_test_case_list = [
    init_test_case_001,
    // init_test_case_002,
];

const run_mocha = function (pParameter) {
    if (!pParameter) pParameter = {};

    const document = global.document;
    const mocha = global.mocha;
    const expect = global.expect;

    document.querySelector(`#mocha`).innerHTML = ``;
    mocha.setup({
        ui: 'bdd',
        timeout: 10000,
    });
    //需要在执行完mocha.setup后，才有这个全局对象
    const describe = global.describe;
    const it = global.it;

    //可以多次mocha.run
    //参考：https://github.com/mochajs/mocha/pull/4234
    mocha._cleanReferencesAfterRun = false;

    if (mocha.suite.suites.length <= 0) {
        _.each(init_test_case_list, function (init_test_case) {
            init_test_case({
                describe,
                it,
                expect,
            });
        });
    }

    mocha.run();
};

module.exports = run_mocha;
